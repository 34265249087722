export default class UploaderHandler {
  constructor(uploader, holder, stimulator, canceller='.img-upload-cancel', evt='change') {
    this.uploader = $(uploader);
    this.holder = $(holder);
    this.stimulator = $(stimulator);
    this.canceller = $(canceller);
    this.max_mb_size = 100.0;

    this._bind_listeners(evt);
  }

  _bind_listeners(evt) {
    const _stim = this.stimulator;
    this.uploader.on('click', function() {
      this.stimulator.trigger('click');
    }.bind(this));

    this.stimulator.on(evt, function(event){
      this._read_file(event.target);
    }.bind(this));

    this.canceller.click((e) => {
      this._reset_ui();
      this.uploader.removeAttr('data-updated');
    });
  }

  _read_file(input) {
    var reader = new FileReader();

    if (input.files && input.files[0]) {
      reader.onload = (e) => {
        var src = e.target.result;

        if(input.files[0].type.includes('video')){
          this.holder.before('\
            <video controls="" src="' + src + '" class="w-100"></video>\
          ').addClass('d-none')
          this.holder.parent().children('video').css({
            height: this.holder.innerHeight(),
            width: this.holder.innerWidth()
          })
          this.holder.removeClass('is-invalid')
          this.holder.parent().find('.invalid-feedback').remove()
        } else
          this.holder.attr('style', `background-image: url(${src})`)


        this.holder.addClass('contains')
        this.canceller.removeClass('invisible');
        this.stimulator[0].files = input.files;
      }

      reader.readAsDataURL(input.files[0]);
    } else {
      this._reset_ui();
    }
  }


  _reset_default_img() {
    if(this.uploader.data('default-image')) 
      this._default_img = `background-image: url(${this.uploader.data('default-image')});`;
    else this._default_img = this.holder.attr('style');
  }


  _reset_ui() {
    if (parseInt(this.uploader.attr('data-updated')))
      this._reset_default_img();

    this.canceller.addClass('invisible');
    if(this.holder.hasClass('as-video')){
      this.holder.parent().children('video').remove()
      this.holder.removeClass('d-none')
    } else
      this.holder.attr('style', '');
    this.holder.removeClass('contains')
    this.uploader.val('');
    this.stimulator.val('');
  }


  verify_file_size(form) {
    $(form).submit(function(e) {
      var vr_mb_size = 0;
      if(this.file_count > 0) vr_mb_size = this.mb_file_size

      if(vr_mb_size > this.max_mb_size) {
        this._reset_ui();
        $('invalid-feedback').remove()
        this.holder.addClass('is-invalid')
        this.holder.parent().append('\
          <small class="invalid-feedback d-block">\
          File size exceeds maximum limit of ' + this.max_mb_size + 'MB.\
          </small>\
        ')
        Rails.stopEverything(e);
        var scroller = this.holder.closest('.review-items')

        console.log(scroller)

        if(scroller.length > 0)
          scroller.animate({ scrollTop: 0 }, 'slow');

      }
    }.bind(this));
  }

  get mb_file_size() {
    return parseFloat((this.stimulator[0].files[0].size / 1000000).toFixed(2))
  }

  get file_count() {
    return this.stimulator[0].files.length
  }


  get default_img() {
    return this._default_img;
  }

}